export const environment = {
  production: false,
  endpointManager: 'https://sandbox-code.biz.com.br/allinone-bff-manager',
  endpointBenefits: 'https://sandbox-code.biz.com.br/allinone-bff-benefits',
  endpointOnboarding:
    'https://sandbox-code.biz.com.br/allinone/portalonboarding',
  endpointCards: 'https://sandbox-code.biz.com.br/allinone-bff-card',
  endpointPypefy: 'https://sandbox-code.biz.com.br/crm',
  endpointViaCep: 'https://viacep.com.br/ws',
  endpontReceita: 'https://www.receitaws.com.br',
  subscriptionKey: '73764d2c82f847b4bdd1363cc3749f31',

  issuer: {
    productId: '93',
    affinityGroupId: '3',
  },
};
