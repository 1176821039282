import { FormsModule } from '@angular/forms';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageService } from '@shared/services/shared-services/storage.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';

import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ColorBlockModule } from 'ngx-color/block';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomDateAdapter } from './_shared/locale/custom-date-adapter';
import { HeaderInterceptor } from './_shared/interceptors/header.interceptor';
import { ErrorInterceptor } from './_shared/interceptors/http-error-interceptor';
import { SessionService } from './_shared/services/shared-services/session.service';
import { CustomSnackbarModule } from './_shared/components/ui-elements/custom-snackbar/custom-snackbar.module';

registerLocaleData(localePt);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    CustomSnackbarModule,
    FormsModule,
    BreadcrumbModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxPaginationModule,
    CarouselModule,
    ColorBlockModule,
    MatTableModule,
    MatSnackBarModule,
    MatDialogModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    StorageService,
    SessionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
