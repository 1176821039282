import { Model } from './model';

////////////////////////////////////// Company ////////////////////////////////////////////

export class CompanyModel extends Model {
  companyId!: string;
  masterCompanyId!: null | string;
  fantasyName!: string;
  appName!: string;
  companyName!: string;
  document!: string;
  email!: string;
  phone!: string;
  financialEmail!: string;
  branding!: BrandingModel;
  companyType!: string;
  companyTypeId!: number;

  address!: CompanyAddressModel;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class updateMyBrandModel extends Model {
  appName?: string;
  branding?: BrandingModel;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export interface CompanyListModel {
  companyId: string;
  companyName: string;
  fantasyName: string;
  document: string;
  email: string;
  financialEmail: string;
  phone: string;
  disableSelection: string;
}

export class CompanyUpdateModel extends Model {
  fantasyName?: string;
  phone?: string;
  email?: string;
  financialEmail?: string;
  appName?: string;
  branding?: BrandingModel;

  address?: CompanyAddressModel;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class BrandingModel extends Model {
  logo?: string;
  icon?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;

  logoApp?: string;
  primaryFontColor?: string;
  secondaryFontColor?: string;
  tertiaryFontColor?: string;
  primaryColorApp?: string;
  secondaryColorApp?: string;
  tertiaryColorApp?: string;
  iconAndroidApp?: string;
  iconIosApp?: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export interface CompanyAccessModel {
  functionalityId: string;
  name: string;
  permissionId: string;
  mobileAccess: boolean;
  mobileAccessChild: Array<CompanyAccessModel>;
}

////////////////////////////////////// CompanyAddress ////////////////////////////////////////////

export class CompanyAddressModel extends Model {
  zipCode?: string;
  streetLine1?: string;
  number?: string;
  district?: string;
  city?: string;
  state?: string;
  streetLine2?: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

// ################### #################### SubCompanys #################### ###################

export class SubCompanysModal extends Model {
  companyId!: string;
  companyName!: string;
  fantasyName!: string;
  document!: string;
  email!: string;
  financialEmail!: null;
  phone!: string;
  createdDate!: Date;

  constructor(objeto?: any) {
    super(objeto);
  }
}
