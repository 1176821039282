import { Model } from './model';

export class UpdatePersonalData extends Model {
  name?: string;
  surname?: string;
  displayName?: string;
  email?: string;
  mobilePhone?: string;
  isAdmin?: boolean;
  image?: any;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class updatePasswordModel extends Model {
  password!: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class PersonDataModel extends Model {
  userId!: string;
  userName!: string;
  surname!: string;
  displayName!: string;
  email!: string;
  mobilePhone!: string;
  document!: string;
  changedDate!: Date;
  image!: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class UserModel extends Model {
  userId!: string;
  displayName!: string;
  document!: string;
  profileName!: string;
  userType!: number;
  accessIsActive!: boolean;
  lastChangeDate!: Date | null;
  changeUserId!: string;
  changeUserName!: string;
  image?: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export interface AddUserModel {
  isAdmin: boolean;
  document: string;
  name: string;
  surname?: string;
  email: string;
  displayName: string;
  mobilePhone: string;
  issuer?: string;
  sendEmail?: boolean;
  userType?: number;
}

export interface exportUserXBearer {
  companyId: string;
  name: string;
  document: string;
  lastChangeStartDate?: string;
  lastChangeFinishDate?: string;
  active: boolean | null;
  profile?: string;
  userType: number;
}

export class HandleInLotModel extends Model {
  property?: string;
  message?: string;

  constructor(object?: any) {
    super(object);
  }
}
