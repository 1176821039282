import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthRedirectGuard } from '@shared/can-activate/auth-redirect-guard.service';

const routes: Routes = [
  {
    path: 'allinone',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layout/layout.module').then((m) => m.LayoutModule),
      },
    ],
  },
  {
    path: 'internet-banking',
    loadChildren: () =>
      import('./portals/internet-banking/manage-menu/manage-menu.module').then(
        (m) => m.ManageMenuModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./empty/empty.module').then((m) => m.EmptyModule),
    canLoad: [AuthRedirectGuard],
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'allinone/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
