import { Model } from './model';
import { Step } from '../enum/step.enum';
import { LicenseModel } from './manager/license.model';

////////////////////////////////////// Access ////////////////////////////////////////////

export class AccessUserModel extends Model {
  accessId!: string;
  profileId!: string;
  profileName!: string;
  companyId!: string;
  companyName!: string;
  companyFantasyName!: string;
  companyDocument!: string;
  isActive!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class AccessLastIWrapper extends Model {
  userId!: string;
  userName!: string;
  lastAccess!: LastAccessModel;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class AccessAllIWrapper extends Model {
  userId!: string;
  userName!: string;
  userImage!: string;
  accessId!: string;
  profileId!: string;
  profileName!: string;
  companyId!: string;
  companyName!: string;
  companyFantasyName!: string;
  companyDocument!: string;
  companyRequiresOrderApproval!: boolean;
  companyIcon!: string;
  companyLogo!: string;
  companyPrimaryColor!: string;
  companyTypeId!: number;
  companyType!: string;
  masterCompanyId!: string;
  isActive!: boolean;
  lastAccess!: boolean;
  companyTransferFileAllowed!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class LastAccessModel extends Model {
  accessId!: string;
  profileId!: string;
  profileName!: string;
  companyId!: string;
  companyName!: string;
  companyFantasyName!: string | undefined;
  companyDocument!: string;
  isActive!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class AccessLastModel extends Model {
  lastAccess?: boolean;
  isActive?: boolean;
  profileId?: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

////////////////////////////////////// Permission ////////////////////////////////////////////

export class PermissionModel extends Model {
  permissionId!: string;
  profileId!: string;
  functionalityId!: string;
  modified?: boolean;
  read!: boolean;
  create!: boolean;
  update!: boolean;
  delete!: boolean;
  report!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}
export class AddPermissionModel extends Model {
  functionalityId!: string;
  all?: boolean;
  read!: boolean;
  create!: boolean;
  update!: boolean;
  delete!: boolean;
  report!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class EditPermissionModel extends Model {
  permissionId?: string;
  all?: boolean;
  read!: boolean;
  create!: boolean;
  update!: boolean;
  delete!: boolean;
  report!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class EditPermissionFormGroupModel extends Model {
  permissionId!: string;
  all?: boolean;
  read!: boolean;
  create!: boolean;
  update!: boolean;
  delete!: boolean;
  report!: boolean;

  functionalityIdx!: string;
  functionalityId!: string;
  name!: string;
  tag!: string;
  profileId!: string;
  newPermissions?: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

////////////////////////////////////// Product ////////////////////////////////////////////

export class ProductModel extends Model {
  productId!: string;
  name!: string;
  description!: string;
  image!: string;
  icon!: string;
  salable!: boolean;
  onboardingId!: string;
  onboardingStep!: Step;
  active: boolean = false;
  partnerId!: string;
  accountTypeId!: number;
  accountTypeDescription!: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

////////////////////////////////////// Platform ////////////////////////////////////////////
export class platformModel extends Model {
  platformId!: string;
  description!: string;
  active: boolean = false;
}

////////////////////////////////////// Profile ////////////////////////////////////////////

export class ProfileModel extends Model {
  profileId!: string;
  disableSelection!: string;
  name!: string;
  licenses!: Array<LicenseModel>;

  constructor(objeto?: any) {
    super(objeto);
  }
}
export class AddProfileModel extends Model {
  profileId?: string;
  name!: string;
  licenseIds!: Array<string>;

  constructor(objeto?: any) {
    super(objeto);
  }
}

//////////////////////////////////////////Manager-Functionality
export class featuresUserWrapper extends Model {
  profileId!: string;
  name!: string;
  licenses!: LicensesModel[];

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class LicensesModel extends Model {
  licenseId!: string;
  productId!: string;
  productName!: string;
  productDescription!: string;
  features!: Array<features>;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class features extends Model {
  permissionId!: string;
  profileId!: string;
  functionalityId!: string;
  productId!: string;
  name!: string;
  tag!: string;
  read!: boolean;
  create!: boolean;
  update!: boolean;
  delete!: boolean;
  report!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}

////////////////////////////////////// Company App Build ////////////////////////////////////////////

export class BuildsAppsModel extends Model {
  id!: string;
  companyId!: string;
  logoApp!: string;
  appStore!: number;
  appName!: string;
  appVersion!: null;
  betaTester!: boolean;
  status!: number;
  buildStartDate!: Date;
  buildFinishDate!: Date;
  commitHash!: null;
  buildIdExpo!: null;
  requestedBy!: string;
  appSlug!: string;

  constructor(objeto?: any) {
    super(objeto);
  }
}

export class CreatedBuildModel extends Model {
  requestedBy!: string;
  builds!: Build[];

  constructor(objeto?: any) {
    super(objeto);
  }
}
export class Build extends Model {
  companyId!: string;
  appStore!: number;
  betaTester!: boolean;
  submitToStore!: boolean;

  constructor(objeto?: any) {
    super(objeto);
  }
}
