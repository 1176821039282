import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ParamValue } from '../../models/param-value.model';
import { QueryOptions } from '../../models/query-options.model';
import { Wrapper } from '../../models/pagination-wrapper';
import {
  AccessLastIWrapper,
  AddProfileModel,
  AddPermissionModel,
  PermissionModel,
  EditPermissionModel,
  AccessLastModel,
  platformModel,
  ProductModel,
  AccessUserModel,
  BuildsAppsModel,
  CreatedBuildModel,
  AccessAllIWrapper,
} from '../../models/manager.model';

@Injectable({ providedIn: 'root' })
export class ManagerService {
  private urlPermission = environment.endpointManager;

  public userSubject = new BehaviorSubject<any | null>(null);
  public accessAll = new BehaviorSubject<any | null>(null);
  public companyLogged = new BehaviorSubject<any | null>(null);
  public companySubject = new BehaviorSubject<any>(null);
  public myBrandSubject = new BehaviorSubject<any>(null);
  public companyIdSubject = new BehaviorSubject<string>('');
  public counterNotificationSubject = new BehaviorSubject<number>(0);
  public sendNotificationSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  ////////////////////////////////////// Access ////////////////////////////////////////////
  public getAccessUserId(
    userId: string,
    param?: Array<ParamValue>
  ): Observable<Wrapper<AccessUserModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<AccessUserModel>>(
        `${this.urlPermission}/user/${userId}/access`,
        httpOptions
      )
      .pipe(map((response) => new Wrapper<AccessUserModel>(response)));
  }

  public getAccessLast(
    userId: string,
    param?: Array<ParamValue>
  ): Observable<AccessLastIWrapper> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<AccessLastIWrapper>(
        `${this.urlPermission}/user/${userId}/access/last`,
        httpOptions
      )
      .pipe(map((response) => new AccessLastIWrapper(response)));
  }

  public getAccessAll(userId: string): Observable<Array<AccessAllIWrapper>> {
    return this.http
      .get<Array<AccessAllIWrapper>>(
        `${this.urlPermission}/user/${userId}/access/all`
      )
      .pipe(map((response) => response));
  }

  public updateAccessLast(
    userId: string,
    accessId: string,
    requestBody: AccessLastModel
  ): Observable<any> {
    return this.http
      .patch<AccessLastModel>(
        `${this.urlPermission}/user/${userId}/access/${accessId}`,
        requestBody
      )
      .pipe(map((response) => response));
  }

  ////////////////////////////////////// Permission ////////////////////////////////////////////

  public getPermissions(
    param: Array<ParamValue>
  ): Observable<Wrapper<PermissionModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<PermissionModel>>(
        `${this.urlPermission}/Permission`,
        httpOptions
      )
      .pipe(map((response) => new Wrapper<PermissionModel>(response)));
  }

  public addPermission(
    permission: Array<AddPermissionModel>,
    profileId: string
  ): Observable<PermissionModel> {
    return this.http
      .post<AddProfileModel>(
        `${this.urlPermission}/Permission/profile/${profileId}`,
        permission
      )
      .pipe(map((response) => new PermissionModel(response)));
  }

  public editPermission(
    permissionId?: string,
    permission?: EditPermissionModel
  ): Observable<any> {
    return this.http
      .patch<AddProfileModel>(
        `${this.urlPermission}/Permission/${permissionId}`,
        permission
      )
      .pipe(map((response) => response));
  }

  ////////////////////////////////////// Company App Build ////////////////////////////////////////////

  public getBuildsApps(
    param: Array<ParamValue>
  ): Observable<Wrapper<BuildsAppsModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<BuildsAppsModel>>(
        `${this.urlPermission}/company/build`,
        httpOptions
      )
      .pipe(map((response) => new Wrapper<BuildsAppsModel>(response)));
  }

  public postCreatedBuild(requestBody: CreatedBuildModel): Observable<any> {
    return this.http
      .post<any>(`${this.urlPermission}/company/build`, requestBody)
      .pipe(map((response) => response));
  }

  ////////////////////////////////////// Product ////////////////////////////////////////////

  public getProduct(
    param?: Array<ParamValue>
  ): Observable<Wrapper<ProductModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<ProductModel>>(`${this.urlPermission}/Product`, httpOptions)
      .pipe(map((response) => new Wrapper<ProductModel>(response)));
  }

  ////////////////////////////////////// Platform ////////////////////////////////////////////

  public getPlatform(
    param?: Array<ParamValue>
  ): Observable<Wrapper<platformModel>> {
    const params = new QueryOptions(param).getUrlSearchParams();

    const httpOptions = {
      params,
    };
    return this.http
      .get<Wrapper<platformModel>>(
        `${this.urlPermission}/Platform`,
        httpOptions
      )
      .pipe(map((response) => new Wrapper<platformModel>(response)));
  }

  // ################### #################### SUBJECT #################### ###################

  setAccessAllSubject(item: Array<AccessAllIWrapper>): void {
    this.accessAll.next(item);
  }

  getAccessAllSubjectObservable(): Observable<Array<AccessAllIWrapper>> {
    return this.accessAll.asObservable();
  }

  setCompanyLoggedSubject(item: AccessAllIWrapper): void {
    this.companyLogged.next(item);
  }

  getCompanyLoggedSubjectObservable(): Observable<AccessAllIWrapper> {
    return this.companyLogged.asObservable();
  }

  // ################### #################### SUBJECT #################### ###################

  setUserSubject(item: any): void {
    this.userSubject.next(item);
  }
  getUserSubjectObservable(): Observable<any> {
    return this.userSubject.asObservable();
  }

  getUserSubject(): any | null {
    return this.userSubject.getValue() || null;
  }

  // ################### #################### SUBJECT MYBRAND #################### ###################

  setMyBrandSubject(item: any): void {
    this.myBrandSubject.next(item);
  }
  getMybrandSubject(): Observable<any> {
    return this.myBrandSubject.asObservable();
  }

  // ################### #################### SUBJECT COMPANYID #################### ###################

  setCompanyIdSubject(item: string): void {
    this.companyIdSubject.next(item);
  }
  getCompanyIdSubject(): Observable<string> {
    return this.companyIdSubject.asObservable();
  }
  // ################### #################### SUBJECT NOTIFICATION #################### ###################

  setNotificationSubject(item: number): void {
    this.counterNotificationSubject.next(item);
  }
  getNotificationSubject(): Observable<number> {
    return this.counterNotificationSubject.asObservable();
  }
  setSendNotificationSubject(item: boolean): void {
    this.sendNotificationSubject.next(item);
  }
  getSendNotificationSubject(): Observable<boolean> {
    return this.sendNotificationSubject.asObservable();
  }
}
