import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Subscription, finalize } from 'rxjs';

import { CompanyService } from './_shared/services/frame-services/company.service';
import { ManagerService } from './_shared/services/frame-services/manager.service';
import { StorageService } from '@shared/services/shared-services/storage.service';

import { MixinHandler } from './_shared/mixins/mixin-handler';
import { GeneralHttpErrorMixin } from './_shared/mixins/general-http-error-mixin';
import { ParamValue } from './_shared/models/param-value.model';

import { LoadingComponent } from './_shared/components/loading/loading.component';
import { AccessAllIWrapper } from './_shared/models/manager.model';
import { environment } from 'src/environments/environment';
import { ThemeService } from '@shared/services/shared-services/theme.service';
import { PermissionService } from '@shared/services/shared-services/permission.service';
import { LincenseService } from '@shared/services/frame-services/license.service';
import { LicenseModel } from '@shared/models/manager/license.model';
import { UserService } from '@shared/services/frame-services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public searchParams = new Array<ParamValue>();
  private invertGray = false;
  private invertContrast = false;
  private body!: HTMLElement;
  private subscription: Subscription = new Subscription();
  private userIdsubscription: Subscription = new Subscription();
  private handleHttpError!: (
    customMessageError: string,
    httpError: HttpErrorResponse,
    reqMethod: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET'
  ) => void;

  private loadingDialog!: MatDialogRef<LoadingComponent>;
  public openDialogLoading!: () => void;

  constructor(
    private themeService: ThemeService,
    private managerService: ManagerService,
    private lincenseService: LincenseService,
    private companyService: CompanyService,
    private storageService: StorageService,
    private permissionService: PermissionService,
    private userService: UserService,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    MixinHandler.applyMixins(AppComponent, [GeneralHttpErrorMixin]);

    this.themeService.changeTheme();

    this.userIdsubscription.add(
      this.storageService.watchStorage().subscribe(() => {
        if (this.storageService.getItem('userId')) {
          this.getAccessAllCompany();

          this.userIdsubscription.unsubscribe();
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.body = document.body;
    this.grayscale(this.storageService.getItem('grayscale'));
    this.contrast(this.storageService.getItem('contrast'));
  }

  grayscale(invertGray: boolean): void {
    this.invertGray = invertGray;
    this.body.classList.toggle('grayscale', this.invertGray);
  }

  contrast(contrast: boolean): void {
    this.invertContrast = contrast;
    this.body.classList.toggle('invert', this.invertContrast);
  }

  private getAccessAllCompany(): void {
    this.subscription.add(
      this.managerService
        .getAccessAll(this.storageService.getItem('userId'))
        .subscribe({
          next: (next) => this.handleAccessLastResponse(next),
          error: (err) =>
            this.handleHttpError('Erro ao carregar companhia!', err, 'GET'),
        })
    );
  }

  public handleAccessLastResponse(resp: Array<AccessAllIWrapper>) {
    this.managerService.setAccessAllSubject(resp);

    let access: AccessAllIWrapper | undefined = resp.find(
      (access) => access.lastAccess === true
    );

    if (!resp.find((access) => access.lastAccess === true)) {
      access = resp[0];
    }

    this.managerService.setCompanyLoggedSubject(access!);

    this.storageService.setItem('companyId', access?.companyId);
    this.storageService.setItem('jobTitle', access?.profileName);
    this.storageService.setItem('profileId', access?.profileId);
    this.storageService.setItem('user', access?.userName);
    // this.storageService.setItem(
    //   'companyRecharge',
    //   access?.companyRequiresOrderApproval
    // );
    // this.storageService.setItem('companyRecharge', true);
    this.userService.setImgUserSubject(access?.userImage);

    this.permissionService.permission();

    this.handleCompanyResponse(access);

    this.getLicense(access!.companyId);
  }

  public handleCompanyResponse(resp: any): void {
    const favIcon: HTMLLinkElement | null = document.querySelector('#app-icon');

    this.companyService.setCompanyResponseSubject(resp);

    this.storageService.setItem('masterCompanyId', resp.masterCompanyId);
    this.storageService.setItem('typeC', resp.companyTypeId);

    if (this.storageService.getItem('company') === 'elo') {
      this.themeService.changeTheme('#003933');
      if (favIcon) {
        favIcon.href = environment.production
          ? 'https://cdn-aiofiles-prd.biz.com.br/site/clients/bb/logoEloCir.png'
          : 'https://cdn-aiofiles.biz.com.br/site/clients/bb/logoEloCir.png';
      }
      return;
    }

    if (this.storageService.getItem('company') === 'biz') {
      if (resp?.companyIcon && favIcon) {
        favIcon.href = resp.companyIcon;
      }
    }

    if (this.storageService.getItem('company') === 'raio' && favIcon) {
      favIcon.href = environment.production
        ? 'https://cdn-aiofiles-prd.biz.com.br/site/imgs/raio-icon.png'
        : 'https://cdn-aiofiles.biz.com.br/site/imgs/raio-icon.png';
    } else if (this.storageService.getItem('company') === 'elo' && favIcon) {
      favIcon!.href = environment.production
        ? 'https://cdn-aiofiles-prd.biz.com.br/site/clients/bb/logoEloCir.png'
        : 'https://cdn-aiofiles.biz.com.br/site/clients/bb/logoEloCir.png';
    }

    this.themeService.changeTheme(resp?.companyPrimaryColor);
  }

  private getLicense(companyId: string): void {
    this.subscription.add(
      this.lincenseService
        .getLicenses(companyId)
        .pipe(finalize(() => this.loadingDialog.close()))
        .subscribe({
          next: (next) => this.getProductsAndLicensesSuccess(next),
          error: (err) =>
            this.handleHttpError(
              'Erro na consulta de produtos e licenças',
              err,
              'GET'
            ),
        })
    );
  }

  private getProductsAndLicensesSuccess(getLicenseSuccess: LicenseModel[]) {
    this.lincenseService.setLicensesSubject(getLicenseSuccess);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
